.about-section {
    padding: 2rem 2rem;
    background-color: #f4f4f4;
    text-align: center;
}

.about-title {
    font-size: 2.5rem;
    margin-bottom: 2rem;
}

.about-text {
    max-width: 800px;
    margin: 0 auto;
    font-size: 1.2rem;
}
