html, body {
    overflow-x: hidden;
}
.navbar {
    background: #000;
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 10;
    height: 4rem;
}

.navbar-logo {
    color: #fff;
    font-size: 1.8rem;
    font-weight: bold;
}

.navbar-links {
    display: flex;
}

.navbar-link {
    color: #fff;
    margin: 0 1rem;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.1rem;
}

.navbar-link:hover {
    color: #f39c12;
}

.menu-icon {
    display: none;
    font-size: 2rem;
    cursor: pointer;
    color: #fff;
}

.sidebar {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100%;
    background: #000;
    padding-top: 60px;
    transition: 0.3s;
    z-index: 20;
}

.sidebar.active {
    display: block;
}

.sidebar-link {
    padding: 10px 15px;
    text-decoration: none;
    font-size: 1.1rem;
    color: #fff;
    display: block;
    transition: 0.3s;
}

.sidebar-link:hover {
    color: #f39c12;
}

.close-icon {
    font-size: 2rem;
    color: #fff;
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
}

@media (max-width: 768px) {
    .navbar {
        justify-content: flex-start;
    }

    .menu-icon {
        display: block;
        order: -1;
        margin-right: 1rem;
    }

    .navbar-links {
        display: none;
    }
}

@media (min-width: 769px) {
    .navbar-links {
        margin-left: auto;
    }
}