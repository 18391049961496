.experience-section {
    padding: 2rem 2rem;
    background-color: #fff;
}

.experience-title {
    text-align: left;
    font-size: 2.5rem;
    margin-bottom: 2rem;
}

.experience-content {
    background: #f9f9f9;
    padding: 1.5rem;
    margin: 1rem 0;
    border-left: 5px solid #f39c12;
}

/* Add media queries for mobile compatibility */
@media (max-width: 768px) {
    .experience-section {
        padding: 1rem 1rem;
    }

    .experience-title {
        font-size: 2rem;
    }

    .experience-content {
        padding: 1rem;
    }
}