.hero {
    height: calc(100vh - 6rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url('../img/port-background.png') no-repeat center center/cover;
    color: #fff;
    position: relative;
    overflow: hidden;
}

.hero-title {
    font-size: 4rem;
    margin-bottom: 1rem;
}

.hero-subtitle {
    font-size: 1.5rem;
    margin-bottom: 2rem;
}

.hero-button {
    padding: 1rem 2rem;
    background-color: #f39c12;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    margin: 0.5rem;
}

.hero-button:hover {
    background-color: #d35400;
}

.scroll-down-arrow {
    position: absolute;
    bottom: 20px;
    font-size: 2rem;
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    animation: bounce 2s infinite;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
}

@media (max-width: 768px) {
    .hero-title {
        font-size: 2.5rem;
        text-align: center;
    }
}