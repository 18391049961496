.projects-section {
    padding: 2rem 2rem;
    background-color: #f4f4f4;
    text-align: left;
}

.projects-title {
    font-size: 2.5rem;
    margin-bottom: 2rem;
}

.project-content {
    background: #fff;
    padding: 1.5rem;
    margin: 1rem 0;
    border-left: 5px solid #3498db;
}

li {
    padding-bottom: 5px;
}