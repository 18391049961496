.education-section {
    padding: 2rem 2rem;
    background-color: #f4f4f4;
    text-align: center;
}

.education-title {
    font-size: 2.5rem;
    margin-bottom: 2rem;
}

.education-content {
    margin-bottom: 2rem;
    font-size: 1.2rem;
}
