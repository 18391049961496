.footer {
    background-color: #000;
    color: #fff;
    text-align: center;
    padding: 1rem;
    position: relative;
    bottom: 0;
    width: 100%;
}

.footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.linkedin-link {
    color: #fff;
    margin-bottom: 0.5rem;
    text-decoration: none;
}

.linkedin-link:hover {
    color: #0e76a8;
}

.footer p {
    margin-top: 0.5rem;
    font-size: 1rem;
}
